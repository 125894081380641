<template>
  <v-dialog
    max-width="1100"
    width="100%"
    :value="true"
    persistent
    @keydown.esc="close"
  >
    <v-card :loading="loading">
      <v-card-title class="pa-0">
        <v-toolbar color="success" class="mb-2">
          <v-btn icon>
            <v-icon>mdi-cog</v-icon>
          </v-btn>
          <v-toolbar-title>Einstellungen</v-toolbar-title>
          <v-spacer />
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-list>
        <v-list-item>
          <v-input label="Schüler*innen">
            <v-row>
              <v-col></v-col>
              <v-col>
                <DateInput
                  v-model="settings.studentTitleStartDate"
                  label="von"
                />
              </v-col>
              <v-col>
                <TimeInput
                  v-model="settings.studentTitleStartTime"
                  label="von"
                />
              </v-col>
              <v-col>
                <DateInput v-model="settings.studentTitleEndDate" label="bis" />
              </v-col>
              <v-col>
                <TimeInput v-model="settings.studentTitleEndTime" label="bis" />
              </v-col>
            </v-row>
          </v-input>
        </v-list-item>
        <v-list-item>
          <v-input label="Betreuer*innen">
            <v-row>
              <v-col></v-col>
              <v-col>
                <DateInput v-model="settings.coachTitleStartDate" label="von" />
              </v-col>
              <v-col>
                <TimeInput v-model="settings.coachTitleStartTime" label="von" />
              </v-col>
              <v-col>
                <DateInput v-model="settings.coachTitleEndDate" label="bis" />
              </v-col>
              <v-col>
                <TimeInput v-model="settings.coachTitleEndTime" label="bis" />
              </v-col>
            </v-row>
          </v-input>
        </v-list-item>
        <v-list-item>
          <v-input label="Zweitbeurteilung">
            <v-row>
              <v-col></v-col>
              <v-col>
                <DateInput v-model="settings.assessorStartDate" label="von" />
              </v-col>
              <v-col>
                <TimeInput v-model="settings.assessorStartTime" label="von" />
              </v-col>
              <v-col>
                <DateInput v-model="settings.assessorEndDate" label="bis" />
              </v-col>
              <v-col>
                <TimeInput v-model="settings.assessorEndTime" label="bis" />
              </v-col>
            </v-row>
          </v-input>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="loading || saving"
          :loading="loading"
          text
          @click="getData"
          plain
          >verwerfen</v-btn
        >
        <v-btn
          :disabled="loading || saving"
          :loading="saving"
          color="error"
          text
          @click="saveData"
          >Speichern</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DateInput from "common/components/DateInput.vue";
import TimeInput from "common/components/TimeInput.vue";
export default {
  name: "ThesisEvento",
  components: { DateInput, TimeInput },
  data() {
    return {
      loading: false,
      saving: false,
      settings: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    close() {
      this.$router.back();
    },
    async getData() {
      this.loading = true;
      this.settings = await this.apiList({ resource: "thesis/settings" });
      this.loading = false;
    },
    async saveData() {
      this.saving = true;
      await this.apiPost({
        resource: "thesis/settings",
        data: this.settings,
      });
      this.saving = false;
      this.$root.showSuccess("Änderungen erfolgreich gespeichert!");
    },
  },
};
</script>
